import { graphql } from "gatsby"
import React, { useEffect, useRef, useState } from "react"
import EventBanner from "../components/Banner/EventBanner"
import EventBreadcrumbModule from "../components/BreadcrumbModule/EventBreadcrumbModule"
import EventAgenda from "../components/EventAgenda/EventAgenda"
import EventImageCard from "../components/EventImageCard/EventImageCard"
import EventReview from "../components/EventReview/EventReview"
import Partners from "../components/Partners/Partners"
import PatternBanner from "../components/PatternBanner/PatternBanner"
import TileBlock from "../components/TileBlock/TileBlock"
import TileBlockRight from "../components/TileBlockRight/TileBlockRight"
import Layout from "../components/layout"
import SEO from "../components/seo"

let InvestorFormFields = require("../../static/forms/investor_form.json")

const InvestorPageTemplate = ({ data }) => {
  const myRef = useRef(null)
  const executeScroll = () => {
    myRef?.current?.scrollIntoView({ behavior: "smooth" })
  }
  const PageData = data?.strapiInvestor
  const _siteData = data?.strapiSiteConfig
  const [renderComponent, setRenderComponent] = useState(false)
  if (PageData.choose_language === "german") {
    InvestorFormFields = require("../../static/forms-german/investor_form.json")
  }
  useEffect(() => {
    window.addEventListener("mousemove", () => {
      if (renderComponent === false) {
        setRenderComponent(true)
      }
    })
    window.addEventListener("touchmove", () => {
      if (renderComponent === false) {
        setRenderComponent(true)
      }
    })
    window.addEventListener("keypress", () => {
      if (renderComponent === false) {
        setRenderComponent(true)
      }
    })
  })

  return (
    <Layout dark hidemenu>
      <div className="layout-padding-top" />
      <EventBreadcrumbModule title={PageData?.title} tag="menu" />
      {PageData.add_banner && (
        <EventBanner
          title={PageData.add_banner.title}
          {...PageData.add_banner}
          id={PageData.strapi_id}
          imagetransforms={PageData?.imagetransforms}
          istopbanner
          FormFields={InvestorFormFields}
          pageTitle={`Investor - ${PageData.title}`}
          investor
        />
      )}
      {PageData?.add_partner?.length > 0 && (
        <Partners
          title={PageData?.partner_title}
          add_partner={PageData.add_partner}
          id={PageData.strapi_id}
          imagetransforms={PageData?.imagetransforms}
        />
      )}
      {PageData.add_tail?.item?.length > 0 && (
        <EventImageCard
          topno
          {...PageData.add_tail}
          id={PageData.strapi_id}
          imagetransforms={PageData.imagetransforms}
        />
      )}
      {PageData.schedule?.schedule?.length > 0 && (
        <EventAgenda
          {...PageData.schedule}
          id={PageData.strapi_id}
          imagetransforms={PageData.imagetransforms}
        />
      )}

      {PageData?.image_and_content &&
        PageData?.image_and_content?.image_alignment === "right" && (
          <TileBlockRight
            event
            {...PageData?.image_and_content}
            id={PageData.strapi_id}
            imagetransforms={PageData.imagetransforms}
            data={PageData}
          />
        )}
      {PageData?.image_and_content &&
        PageData?.image_and_content?.image_alignment === "left" && (
          <TileBlock
            event
            {...PageData?.image_and_content}
            id={PageData.strapi_id}
            imagetransforms={PageData.imagetransforms}
          />
        )}
      {PageData.review_block?.add_review?.length > 0 && (
        <EventReview
          {...PageData.review_block}
          id={PageData.strapi_id}
          imagetransforms={PageData.imagetransforms}
        />
      )}
      {PageData?.cta && (
        <PatternBanner
          {...PageData?.cta}
          id={PageData.strapi_id}
          imagetransforms={PageData.imagetransforms}
          executeScroll={executeScroll}
        />
      )}
      {PageData.icon_block?.item?.length > 0 && (
        <EventImageCard
          {...PageData.icon_block}
          id={PageData.strapi_id}
          imagetransforms={PageData.imagetransforms}
          mobrowcount={3}
        />
      )}
      <div ref={myRef} />
      {PageData.add_banner && (
        <EventBanner
          {...PageData.add_banner}
          id={PageData.strapi_id}
          imagetransforms={PageData?.imagetransforms}
          title={PageData.footer_block.title}
          content={PageData.footer_block.content}
          image={PageData.footer_block.image}
          FormFields={InvestorFormFields}
          pageTitle={`Investor - ${PageData.title}`}
          investor
        />
      )}
    </Layout>
  )
}

export const Head = ({ data }) => {
  const PageData = data?.strapiInvestor
  const _siteData = data?.strapiSiteConfig

  // var schemaSameAs = ''
  // if (siteData?.facebook_link.length > 1) {
  //   schemaSameAs = siteData?.facebook_link + ','
  // }
  // if (siteData?.twitter_link.length > 1) {
  //   schemaSameAs += siteData?.twitter_link + ','
  // }
  // if (siteData?.instagram_link.length > 1) {
  //   schemaSameAs += siteData?.instagram_link + ','
  // }
  // if (siteData?.linkedin_link.length > 1) {
  //   schemaSameAs += siteData?.linkedin_link + ','
  // }

  // var ldJson = {
  //   "@context": "https://schema.org",
  //   "@type": "Organization",
  //   "url": process.env.GATSBY_SITE_URL,
  //   "name": process.env.GATSBY_SITE_NAME,
  //   "alternateName": process.env.GATSBY_SITE_NAME,
  //   "logo": process.env.GATSBY_SITE_URL + `/images/logo.png`,
  //   "contactPoint": {
  //     "@type": "ContactPoint",
  //     "telephone": siteData?.mobile_device_phone,
  //     "areaServed": process.env.GATSBY_DEFAULT_AREA,
  //     "contactType": "Sales"
  //   },
  //   "sameAs": schemaSameAs.replace(/,\s*$/, "").split(',')
  // };

  return (
    <SEO
      title={
        PageData?.seo?.metaTitle ? PageData?.seo?.metaTitle : PageData?.title
      }
      description={
        PageData?.seo?.metaDescription
          ? PageData?.seo?.metaDescription
          : PageData?.title
      }
    >
      {/* <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(ldJson) }}
        /> */}
    </SEO>
  )
}

export default InvestorPageTemplate

export const query = graphql`
  query ($page_id: String) {
    strapiInvestor(id: { eq: $page_id }) {
      title
      strapi_id
      partner_title
      choose_language
      add_banner {
        form_title
        form_content
        title
        link {
          link_type
          cta_label
          cta_link {
            slug
          }
        }
        image {
          url
          alternativeText
        }
        content {
          data {
            content
          }
        }
      }
      add_tail {
        title
        row
        col
        content {
          data {
            content
          }
        }
        item {
          title
          content {
            data {
              content
            }
          }
          image {
            alternativeText
            url
          }
        }
      }
      review_block {
        title
        content {
          data {
            content
          }
        }
        add_review {
          rating
          name
          designation
          content {
            data {
              content
            }
          }
        }
      }

      schedule {
        schedule {
          title
          time
        }
        title
        content {
          data {
            content
          }
        }
      }
      cta {
        content {
          data {
            content
          }
        }
        image {
          alternativeText
          url
        }
        title
        cta {
          cta_label
          cta_link {
            slug
          }
        }
      }
      icon_block {
        title
        content {
          data {
            content
          }
        }
        col
        row
        item {
          title
          content {
            data {
              content
            }
          }
          image {
            alternativeText
            url
          }
        }
      }
      add_partner {
        logo {
          url
          alternativeText
        }
      }
      footer_block {
        title
        image {
          url
          alternativeText
        }
        content {
          data {
            content
          }
        }
      }
      image_and_content {
        secondary_heading
        primary_heading
        video_url
        strapi_component
        image_alignment
        cta_title
        background_color_transparent
        description {
          data {
            description
          }
        }
        image {
          url
          alternativeText
        }
        cta_link {
          slug
        }
      }
    }
  }
`
